var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", hieght: "700px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.009")) + " "),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "frm" } }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.006")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "polPol",
                          "ctr-cd": _vm.pol.ctrCd,
                          "plc-cd": _vm.pol.plcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePol },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.007")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "podPod",
                          "ctr-cd": _vm.pod.ctrCd,
                          "plc-cd": _vm.pod.plcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePod },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.014")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.param.tmprUnit,
                              expression: "param.tmprUnit",
                            },
                          ],
                          staticClass: "col_2",
                          attrs: { name: "tmpr", id: "tmpr" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.param,
                                  "tmprUnit",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.optionChange,
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "C" } }, [
                            _vm._v("℃"),
                          ]),
                          _c("option", { attrs: { value: "F" } }, [
                            _vm._v("℉"),
                          ]),
                        ]
                      ),
                      _c("e-input-number", {
                        staticClass: "col_3",
                        attrs: {
                          "digit-max": 3,
                          point: 1,
                          "is-minus": true,
                          "is-comma": false,
                          id: "frTmpr",
                        },
                        on: { input: _vm.changeTmpr },
                        model: {
                          value: _vm.param.strSetupTmpr,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "strSetupTmpr", $$v)
                          },
                          expression: "param.strSetupTmpr",
                        },
                      }),
                      _vm._v(" ~ "),
                      _c("e-input-number", {
                        staticClass: "col_3",
                        attrs: {
                          "digit-max": 3,
                          point: 1,
                          "is-minus": true,
                          "is-comma": false,
                          id: "toTmpr",
                        },
                        on: { input: _vm.changeTmpr },
                        model: {
                          value: _vm.param.endSetupTmpr,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "endSetupTmpr", $$v)
                          },
                          expression: "param.endSetupTmpr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("th", [_vm._v("F/P")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        attrs: {
                          "digit-max": 3,
                          point: 1,
                          "is-minus": true,
                          "is-comma": false,
                          id: "flsTmpr",
                        },
                        model: {
                          value: _vm.param.flsTmpr,
                          callback: function ($$v) {
                            _vm.$set(_vm.param, "flsTmpr", $$v)
                          },
                          expression: "param.flsTmpr",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.015")))]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.tempCtrlRsn,
                          expression: "param.tempCtrlRsn",
                        },
                      ],
                      attrs: { type: "text", id: "tempCtrlRsn" },
                      domProps: { value: _vm.param.tempCtrlRsn },
                      on: {
                        keyup: _vm.changeTempCtrlRsn,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.param,
                            "tempCtrlRsn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("msg.SPEC030.016"))),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.017"))),
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.tempProbRsn,
                          expression: "param.tempProbRsn",
                        },
                      ],
                      attrs: { type: "text", id: "tempProbRsn" },
                      domProps: { value: _vm.param.tempProbRsn },
                      on: {
                        keyup: _vm.changeTempProbRsn,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.param,
                            "tempProbRsn",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.018")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "tbl_form", attrs: { id: "unnoForm" } },
                      [
                        _c("span", { staticClass: "dv" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.param.imdunNo,
                                expression: "param.imdunNo",
                              },
                            ],
                            ref: "unno",
                            attrs: { type: "text", id: "unno", disabled: "" },
                            domProps: { value: _vm.param.imdunNo },
                            on: {
                              keyup: _vm.keyupUnno,
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.param,
                                  "imdunNo",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("span", { staticClass: "dv" }, [
                          _c(
                            "button",
                            {
                              staticClass: "search_btn01",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.openPopup("UnnoPop")
                                },
                              },
                            },
                            [_c("span", { staticClass: "offscreen" })]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.019")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", [
                      _c("span", { staticClass: "dv" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.param.imoClasCd,
                              expression: "param.imoClasCd",
                            },
                          ],
                          attrs: {
                            type: "text",
                            disabled: "disabled",
                            id: "imoClasCd",
                          },
                          domProps: { value: _vm.param.imoClasCd },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.param,
                                  "imoClasCd",
                                  $event.target.value
                                )
                              },
                              _vm.checkImoClasCd,
                            ],
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.020")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.pkgGrpNm,
                          expression: "param.pkgGrpNm",
                        },
                      ],
                      attrs: {
                        type: "text",
                        disabled: "disabled",
                        id: "pkgGrpCd",
                      },
                      domProps: { value: _vm.param.pkgGrpNm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "pkgGrpNm", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.021")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.prerShpnNm,
                          expression: "param.prerShpnNm",
                        },
                      ],
                      attrs: {
                        type: "text",
                        disabled: "disabled",
                        id: "prerShpnNm",
                      },
                      domProps: { value: _vm.param.prerShpnNm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "prerShpnNm", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.022")))]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.itemDsc,
                          expression: "param.itemDsc",
                        },
                      ],
                      attrs: { type: "text", id: "itemDsc" },
                      domProps: { value: _vm.param.itemDsc },
                      on: {
                        keyup: _vm.changeItemDsc,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.param, "itemDsc", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.023")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("e-auto-complete-cst-enm", {
                        staticClass: "auto-area position_relative",
                        attrs: {
                          "ctr-cd": _vm.ctrCd,
                          "cd-nm": _vm.actShprCstEnm,
                          id: "actShprCstEnm",
                        },
                        on: { change: _vm.changeActShprCstENm },
                      }),
                    ],
                    1
                  ),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.010")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "tbl_form", attrs: { id: "amtForm" } },
                      [
                        _c(
                          "span",
                          { staticClass: "dv col_6" },
                          [
                            _vm.param.curCd === "USD"
                              ? _c("e-input-number", {
                                  staticClass: "temp_class",
                                  attrs: {
                                    "digit-max": 18,
                                    point: 2,
                                    "is-minus": false,
                                    "is-comma": true,
                                    id: "amt",
                                  },
                                  on: { input: _vm.changeAmt },
                                  model: {
                                    value: _vm.param.amt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "amt", $$v)
                                    },
                                    expression: "param.amt",
                                  },
                                })
                              : _vm._e(),
                            _vm.param.curCd === "KRW"
                              ? _c("e-input-number", {
                                  staticClass: "temp_class",
                                  attrs: {
                                    "digit-max": 18,
                                    "is-minus": false,
                                    "is-comma": true,
                                    id: "amt",
                                  },
                                  on: { input: _vm.changeAmt },
                                  model: {
                                    value: _vm.param.amt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.param, "amt", $$v)
                                    },
                                    expression: "param.amt",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("span", { staticClass: "dv" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.param.curCd,
                                  expression: "param.curCd",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.param,
                                      "curCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  _vm.changeCur,
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "USD" } }, [
                                _vm._v("USD"),
                              ]),
                              _c("option", { attrs: { value: "KRW" } }, [
                                _vm._v("KRW"),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.024")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.bkgShprCstNm,
                          expression: "param.bkgShprCstNm",
                        },
                      ],
                      attrs: { type: "text", disabled: "disabled" },
                      domProps: { value: _vm.param.bkgShprCstNm },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.param,
                            "bkgShprCstNm",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.025")))]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.uploadFileName,
                          expression: "uploadFileName",
                        },
                      ],
                      staticClass: "upload_name",
                      attrs: { type: "hidden", readonly: "", id: "uploadFile" },
                      domProps: { value: _vm.uploadFileName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.uploadFileName = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "a",
                      {
                        class: _vm.$ekmtcCommon.isEmpty(_vm.uploadParam)
                          ? "button sm gray file"
                          : "button sm blue",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.openFileUploader()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.SPEC030.031")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("ul", { staticClass: "bul_list_sm lh_short" }, [
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.027")) + " ")]),
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.028")) + " ")]),
              _c("li", [_vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.029")) + " ")]),
            ]),
          ]),
        ]),
        _vm.repReqNo === ""
          ? _c("div", { staticClass: "mt10 text_center" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.insertRfDgRequest()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("btn.CMBTK013")))]
              ),
              _c(
                "a",
                {
                  staticClass: "button lg",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.SETT070G010.015")))]
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { attrs: { id: "layerPopRe" } },
          [
            _c(
              "win-layer-pop",
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.customPop },
                      on: {
                        close: _vm.closePopupWrap,
                        selectFunc: _vm.selectFunc,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col"),
      _c("col", { attrs: { width: "170px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }