<template>
  <div class="popup_wrap" style="width: 900px; hieght: 700px;">
    <!-- popup_wrap : style="width:600px height:490px" 참고용, 개발시 제거 -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        {{ $t('msg.SPEC030.009') }}
      </h1>
      <div class="content_box">
        <!-- content_box -->
        <form id="frm">
          <table class="tbl_row">
            <colgroup>
              <col width="150px">
              <col>
              <col width="170px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.SPEC030.006') }}</th> <!-- 출발 -->
                <td colspan="3">
                  <e-auto-complete-place
                    id="polPol"
                    @change="changePol"
                    :ctr-cd="pol.ctrCd"
                    :plc-cd="pol.plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.007') }}</th> <!-- 도착 -->
                <td colspan="3">
                  <e-auto-complete-place
                    id="podPod"
                    @change="changePod"
                    :ctr-cd="pod.ctrCd"
                    :plc-cd="pod.plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.014') }}</th> <!-- 세팅온도 -->
                <td>
                  <select name="tmpr" id="tmpr" class="col_2" v-model="param.tmprUnit" @change="optionChange">
                    <option value="C">℃</option>
                    <option value="F">℉</option>
                  </select>
                  <!-- <input type="text" class="col_2" id="frTmpr" v-model="param.strSetupTmpr"> ~ <input type="text" class="col_2" id="toTmpr" v-model="param.endSetupTmpr"> -->
                  <e-input-number @input="changeTmpr" :digit-max="3" :point="1" :is-minus="true" :is-comma="false" v-model="param.strSetupTmpr" id="frTmpr" class="col_3" />
                  ~
                  <e-input-number @input="changeTmpr" :digit-max="3" :point="1" :is-minus="true" :is-comma="false" v-model="param.endSetupTmpr" id="toTmpr" class="col_3" />
                </td>
                <th>F/P</th> <!--F/P(플래시 포인트)-->
                <td>
                  <e-input-number :digit-max="3" :point="1" :is-minus="true" :is-comma="false" v-model="param.flsTmpr" id="flsTmpr" class="" />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.015') }}</th> <!-- 온도제어 목적 -->
                <td colspan="3">
                  <input type="text" id="tempCtrlRsn" v-model="param.tempCtrlRsn" @keyup="changeTempCtrlRsn">
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.016') }}<br /> {{ $t('msg.SPEC030.017') }}</th> <!-- 온도제어 실패시 문제점,위험성-->
                <td colspan="3"><input type="text" id="tempProbRsn" v-model="param.tempProbRsn" @keyup="changeTempProbRsn"></td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.018') }}</th> <!-- UN no.-->
                <td>
                  <div class="tbl_form" id="unnoForm">
                    <span class="dv">
                      <input type="text" id="unno" ref="unno" v-model="param.imdunNo" @keyup="keyupUnno" disabled>
                    </span>
                    <!-- <span class="dv">
                        <a class="button sm" href="#" @click="openPopup('UnnoPop')">{{ $t('msg.SPEC030.030') }}</a>
                      </span> -->
                    <span class="dv">
                      <button type="button" class="search_btn01"
                              @click="openPopup('UnnoPop')"
                      ><span class="offscreen"></span></button>
                    </span>
                  </div>
                </td>
                <th>{{ $t('msg.SPEC030.019') }}</th> <!-- Class-->
                <td>
                  <div>
                    <span class="dv">
                      <input type="text" disabled="disabled" id="imoClasCd" v-model="param.imoClasCd" @input="checkImoClasCd">
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.020') }}</th><!--PACKING GROUP-->
                <td>
                  <input type="text" disabled="disabled" id="pkgGrpCd" v-model="param.pkgGrpNm">
                </td>
                <th>{{ $t('msg.SPEC030.021') }}</th><!--Proper Shipping Name-->
                <td>
                  <input type="text" disabled="disabled" id="prerShpnNm" v-model="param.prerShpnNm">
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.022') }}</th> <!--제품명 및 사용처/용도-->
                <td colspan="3"><input type="text" id="itemDsc" v-model="param.itemDsc" @keyup="changeItemDsc"></td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.023') }}</th> <!--제조사(화주)-->
                <td>
                  <!-- <e-auto-complete-commodity id="actShprCstEnm" @change="changeCommodity" :ctr-cd="ctrCd" :cd-nm="commodityNm" /> -->
                  <e-auto-complete-cst-enm
                    :ctr-cd="ctrCd"
                    :cd-nm="actShprCstEnm"
                    class="auto-area position_relative"
                    id="actShprCstEnm"
                    @change="changeActShprCstENm"
                  />
                </td>
                <th>{{ $t('msg.SPEC030.010') }}</th> <!--Invoice Value-->
                <td>
                  <div class="tbl_form" id="amtForm">
                    <span class="dv col_6">
                      <e-input-number @input="changeAmt" v-if="param.curCd === 'USD'" :digit-max="18" :point="2" :is-minus="false" :is-comma="true" v-model="param.amt" id="amt" class="temp_class" />
                      <e-input-number @input="changeAmt" v-if="param.curCd === 'KRW'" :digit-max="18" :is-minus="false" :is-comma="true" v-model="param.amt" id="amt" class="temp_class" />
                      <!-- <input type="text" id="amt" v-model="param.amt" ref="amt" @change="changeAmt" @blur="isNotNumerCrmMsg($event)"> -->
                    </span>
                    <span class="dv">
                      <select v-model="param.curCd" @change="changeCur">
                        <option value="USD">USD</option>
                        <option value="KRW">KRW</option>
                      </select>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.SPEC030.024') }}</th> <!-- 포워더 -->
                <td>
                  <input type="text" disabled="disabled" v-model="param.bkgShprCstNm">
                </td>
                <th>{{ $t('msg.SPEC030.025') }}</th>
                <td>
                  <input type="hidden" class="upload_name" v-model="uploadFileName" readonly id="uploadFile">
                  <a :class="$ekmtcCommon.isEmpty(uploadParam) ? 'button sm gray file' : 'button sm blue'" href="#" @click="openFileUploader()">{{ $t('msg.SPEC030.031') }}</a>
                </td>
              </tr>
            </tbody>
          </table>

          <ul class="bul_list_sm lh_short">
            <li>
              {{ $t('msg.SPEC030.027') }}
            </li>
            <li>
              {{ $t('msg.SPEC030.028') }}
            </li>
            <li>
              {{ $t('msg.SPEC030.029') }}
            </li>
          </ul>
        </form>
      </div>
      <!-- content_box // -->
      <div v-if="repReqNo === ''" class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="insertRfDgRequest()">{{ $t('btn.CMBTK013') }}</a>
        <a class="button lg" @click="$emit('close')">{{ $t('msg.SETT070G010.015') }}</a>
      </div>
      <div id="layerPopRe">
        <win-layer-pop>
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="customPop"
            @close="closePopupWrap"
            @selectFunc="selectFunc"
          />
        </win-layer-pop>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
/*jshint sub:true*/
import RfDg from '@/api/rest/trans/rfDg'
import UnnoPop from '@/pages/trans/popup/UnnoPop'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'
import UploadPop from '@/pages/trans/popup/SpecialRfDgUploadPop'
import EInputNumber from '@/components/common/EInputNumber'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
export default {
  name: 'SpecialRfDgInqPop',
  components: {
    EBreadcrumbs,
    EAutoCompletePlace,
    EAutoCompleteCommodity,
    RfDg,
    UnnoPop,
    UploadPop,
    EInputNumber,
    WinLayerPop,
    'e-auto-complete-cst-enm': () => import('@/components/common/EAutoCompleteCstEnmV2')
  },

  data: function () {
    return {
      parentInfo: {},
      customComponent: null,
      customPop: null,
      selectFunc: null,
      oogForms: '',
      ctrCd: 'KR',
      cstInfo: {},
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      UnnoPop: {
        unno: '',
        cntrTypCd: 'GP'
      },
      startPlcCd: '',
      startPlcName: '',
      destPlcCd: '',
      startCtrCd: '',
      destCtrCd: '',
      destPlcName: '',
      startPolName: '',
      destPodName: '',
      param: {
        polPortCd: '',
        podPortCd: '',
        strSetupTmpr: '',
        endSetupTmpr: '',
        tmprUnit: 'C',
        tempCtrlRsn: '',
        tempProbRsn: '',
        imdgSeq: '',
        imdunNo: '',
        imoClasCd: '',
        pkgGrpCd: '',
        pkgGrpNm: '',
        prerShpnNm: '',
        itemDsc: '',
        actShprCstEnm: '',
        amt: '',
        curCd: 'USD',
        bkgShprCstNm: '',
        bkgShprCstCd: '',
        fileId: '',
        flsTmpr: ''
      },
      frTmpr: '',
      toTmpr: '',
      repReqNo: '',
      commodityCd: '',
      commodityNm: '',
      asActShprCstNo: '',
      actShprCstEnm: '',
      inquiryDetail: [{ }],
      chkHidden: false,
      uploadParam: [],
      uploadFileName: '',
      uploadFileInfo: []
    }
  },
  watch: {
  },
  created () {
      //this.initCommCodes()
  },
  mounted: function () {
      this.initCommCodes()
  },
  methods: {
    initCommCodes () {
      //this.cstInfo = await RfDg.getCstInfo()
      RfDg.getCstInfo()
      .then(response => {
        console.log('initCommCodes >>> ', response)
        const resp = response.data
        // console.log(resp.cstNo)
        // console.log(resp.cstNm)
        this.param.bkgShprCstCd = resp.cstNo
        this.param.bkgShprCstNm = resp.cstNm
      })
      .catch(err => {
        console.log(err)
      })
    },
    changePol (vo) {
      this.pol = vo
      this.startPolName = vo.plcEnm
      this.startCtrCd = vo.ctrCd
      this.startPlcCd = vo.plcCd
      this.startPlcName = vo.plcEnm
      this.param.polPortCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changePod (vo) {
      this.pod = vo
      this.destPodName = vo.plcEnm
      this.destCtrCd = vo.ctrCd
      this.destPlcCd = vo.plcCd
      this.destPlcName = vo.plcEnm
      this.param.podPortCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changeCommodity (vo) {
      this.commodityNm = vo.cdNm
      this.commodityCd = vo.cd
      this.param.actShprCstEnm = vo.cdNm

      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#actShprCstEnm')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changeActShprCstENm (vo) {
      console.log('실화주 자동 완성 >', vo)
      this.asActShprCstNo = vo.cd
      this.actShprCstEnm = vo.cdNm
      this.param.actShprCstEnm = vo.cdNm

      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#actShprCstEnm')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changeAmt () {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#amt')

      this.localHideErrorTooltip(elem, 'case_two')
    },
    changeCur () {
      this.param.amt = ''
    },
    checkValidationCntrSzCd (frm) {
      var elem = frm.querySelector('#cntrSzCd')
      let msg = 'Please Input Container Size.' //
      this.$ekmtcCommon.showErrorTooltip(elem, msg)
    },
    checkReqInfo () {
      const frm = document.querySelector('#frm')
      var elem = ''

      let isOk = true
      let msg = ''

      if (this.param.podPortCd === '') {
        //msg = 'Please Input POD Code.'
        //elem = frm.querySelector('#podPod')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#podPod'), 'Please Input POD Code.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#podPod'))
      }

      if (this.param.polPortCd === '') {
        //msg = 'Please Input POL Code.'
        //elem = frm.querySelector('#polPol')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#polPol'), 'Please Input POL Code.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#polPol'))
      }

      if (this.param.imdunNo === '') {
        elem = frm.querySelector('#unno')
        msg = 'Please Input UN NO.'
        this.localShowErrorTooltip(elem, msg, 'case_two')

        isOk = false
      } else {
        elem = frm.querySelector('#unno')
        this.localHideErrorTooltip(elem, 'case_two')
      }

      if (this.param.imoClasCd === '') {
        elem = frm.querySelector('#imoClasCd')
        msg = 'Please Input CLASS.'
        this.localShowErrorTooltip(elem, msg, 'case_two')
        isOk = false
      } else {
        elem = frm.querySelector('#imoClasCd')
        this.localHideErrorTooltip(elem, 'case_two')
      }

      if (this.param.strSetupTmpr === '') {
        //msg = 'Please Input Temperature.'
        //elem = frm.querySelector('#frTmpr')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#frTmpr'), 'Please Input Temperature.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#frTmpr'))
      }
      if (this.param.endSetupTmpr === '') {
        //msg = 'Please Input Temperature.'
        //elem = frm.querySelector('#toTmpr')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#toTmpr'), 'Please Input Temperature.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#toTmpr'))
      }
      if (this.param.tempCtrlRsn === '') {
        //msg = 'Please Input the Purpose of temperature control.'
        //elem = frm.querySelector('#tempCtrlRsn')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#tempCtrlRsn'), 'Please Input the Purpose of temperature control.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#tempCtrlRsn'))
      }
      if (this.param.tempProbRsn === '') {
        //msg = 'Please Input the Problems in case of failure to control temperature.'
        //elem = frm.querySelector('#tempProbRsn')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#tempProbRsn'), 'Please Input the Problems in case of failure to control temperature.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#tempProbRsn'))
      }
      if (this.param.itemDsc === '') {
        //msg = 'Please Input the product.'
        //elem = frm.querySelector('#itemDsc')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#itemDsc'), 'Please Input the product.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#itemDsc'))
      }
      if (this.param.actShprCstEnm === '') {
        //msg = 'Please Input the manufacturer.'
        //elem = frm.querySelector('#actShprCstEnm')
        this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#actShprCstEnm'), 'Please Input the manufacturer.')
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#actShprCstEnm'))
      }
      if (this.param.amt === '') {
        msg = 'Please Input the invoice value.'
        elem = frm.querySelector('#amt')
        this.localShowErrorTooltip(elem, msg, 'case_two')
        // this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#amt'), 'Please Input the invoice value.')
        isOk = false
      } else {
        elem = frm.querySelector('#amt')
        this.localHideErrorTooltip(elem, 'case_two')
      }

      return isOk
    },
    optionChange: function () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#amtForm'))
    },
    changeTmpr () {
      const frm = document.querySelector('#frm')
      const elem1 = frm.querySelector('#frTmpr')
      const elem2 = frm.querySelector('#toTmpr')

      if (this.$ekmtcCommon.isNotEmpty(elem1.value) && this.$ekmtcCommon.isNotEmpty(elem2.value)) {
          this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#frTmpr'))
          this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#toTmpr'))
      }
    },
    changeTempCtrlRsn () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#tempCtrlRsn'))
    },
    changeTempProbRsn () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#tempProbRsn'))
    },
    changeItemDsc () {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#itemDsc'))
    },
    isNotNumerCrmMsg (event, obj) {
        var val = event.target.value.replace(/,/g, '')

        if (val === '') return

        var arr = []
        arr = val.split('.')

      var v_normal = /[^0-9.]/g
      if (v_normal.test(val) || isNaN(parseInt(val))) {
        this.openAlert('Numerals can be accepted only.') ////숫자만 입력하세요.
        event.target.value = ''
        event.target.focus()
        return
      }

      if (arr.length > 1 && arr[1].length > 2) {
        this.openAlert('You can enter up to 2 digit under the decimal point') ////소수점이하 2자리까지만 가능합니다.
        event.target.value = ''
        event.target.focus()

        return
      }

      var length_snd = arr[0].length
      if (length_snd > 5) {
        this.openAlert('You can enter up to 5 digit.') ////정수는 5자리까지 입력가능합니다.
        event.target.value = ''
        event.target.focus()
      }
    },
    openPopup (compNm) {
      this.customComponent = UnnoPop

      this.UnnoPop.scheduleFlag = 'Y'
      this.UnnoPop.unno = this.param.imdunNo

      this.customPop = this.UnnoPop
      this.selectFunc = this.setUnnoInfo

      this.$ekmtcCommon.layerOpen('#layerPopRe .popup_dim')
    },
    closePopupWrap () {
      // unno값이 있으면 에러 hide
      if (this.$ekmtcCommon.isNotEmpty(this.param.imdunNo)) {
        this.keyupUnno()
      }

      this.customComponent = null
      this.$ekmtcCommon.layerClose('#layerPopRe .popup_dim')
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose()
    },
    closeFun () {
      this.$Simplert.close()
    },
    setUnnoInfo (obj) {
      console.log(obj)
      this.param.imdunNo = obj.imdunNo
      this.param.imoClasCd = obj.imoClasCd
      this.param.pkgGrpCd = obj.pakgGrpCd
      this.param.pkgGrpNm = obj.pakgGrpNm
      this.param.prerShpnNm = obj.dsc
      this.param.imdgSeq = obj.imdgSeq

      if (this.$ekmtcCommon.isNotEmpty(this.param.imoClasCd)) {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#imoClasCd')
        this.localHideErrorTooltip(elem, 'case_two')
      }
    },
    setUploadInfo (obj) {
      // console.log(obj)
      this.uploadFileInfo = obj

      for (let i = 0; i < obj.length; i++) {
        // 파일업로드 창은 open 후 close 시 현재 첨부된 파일을 계속 리턴하므로
        // 중복 제거를 위한 로직 추가함
        if (this.param.fileId.indexOf(obj[i].fileId, 0) < 0) {
          this.uploadFileName += obj[i].originalName + ' '
          this.param.fileId += obj[i].fileId + ','
        }
        //category: (...)
        //contentType: (...)
        //fileId: (...)
        //guid: (...)
        //new: (...)
        //originalName: (...)
        //size: (...)
        //status: (...)
      }
    },
    keyupUnno () {
      //this.param.imdunNo = obj.imdunNo
      // this.param.imoClasCd = ''
      // this.param.pkgGrpCd = ''
      // this.param.prerShpnNm = ''

      const frm = document.querySelector('#frm')
      let elem = frm.querySelector('#unno')
      this.localHideErrorTooltip(elem, 'case_two')
    },
    checkImoClasCd () {
      const frm = document.querySelector('#frm')
      let elem = frm.querySelector('#imoClasCd')
      this.localHideErrorTooltip(elem, 'case_two')
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    openFileUploader () {
      this.customComponent = UploadPop
      this.customPop = this.uploadParam
      this.selectFunc = this.setUploadInfo

      this.$ekmtcCommon.layerOpen('#layerPopRe .popup_dim')
    },
    insertRfDgRequest () {
      let isAllOk = this.checkReqInfo()

      if (isAllOk) {
        //this.param.setupTmpr = this.frTmpr + ' ~ ' + this.toTmpr
        console.log('insertRfDgRequest ::: ', this.param)
        RfDg.insertRfDg(this.param)
        .then(res => {
            this.openAlert(this.$t('msg.ONEX060P012.072'))//msg.tem.CMAT013
            this.$emit('close')
            //this.repReqNo = res.data.reqNo
            //if (this.repReqNo !== '') {
            //    const detail = Oogs.getOogDetail(this.repReqNo)
            //    detail.then((data) => {
            //      const detailData = data.data
            //      this.inquiryDetail = detailData
            //    })
            //} else {
            //  this.openAlert('fail')
            //}
        })
      }
    },
    localShowErrorTooltip (elem, message, flag) {
        const $elem = $(elem)
        const $parent = $elem.parents('td, div').eq(0)

        let $input = ''
        // let $sParent = ''
        let $sMsgBox = ''
        let $msgBox = $parent.find('.tooltip_essen_box')

        if (flag === 'case_one') {
          $input = $elem.children('div').children('input').eq(0)
          $input.addClass('input_error')
        } else {
          $elem.addClass('input_error')
        }

        if (flag === 'case_two') {
          let $sParent = $parent.parents('td, div').eq(0)
          $sParent.addClass('position_relative')
          let $ctMsgBox = $sParent.find('.tooltip_essen_box')

          $sMsgBox = $sParent.find('.tooltip_essen_box')

          if ($sMsgBox.length === 0) {
          $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
          $msgBox.appendTo($sParent)
          }

          $ctMsgBox.find('.tooltip_essen').eq(0).text(message).show()
        } else {
          $parent.addClass('position_relative')

          if ($msgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parent)
          }
        }
        $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem, flag) {
      const $elem = $(elem)
      const $parent = $elem.parents('td, div').eq(0)
      let $input = ''

      if (flag === 'case_one') {
        $input = $elem.children('div').children('input').eq(0)
        $elem.children('div').children('input').eq(0).removeClass('input_error')
      }

      if (flag === 'case_two') {
        $input = $elem.children('div').children('input').eq(0)
        $elem.children('div').children('input').eq(0).removeClass('input_error')

        let $sParent = $parent.parents('td, div').eq(0)
        let $sMsgBox = $sParent.find('.tooltip_essen_box')

        $sParent.find('.tooltip_essen').hide()
      }

      $elem.removeClass('input_error')
      $parent.find('.tooltip_essen').hide()
    }
  }
}
</script>

<style scoped>
select {
  width: 80px
}
</style>
